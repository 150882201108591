import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Container, Rating } from '@mui/material';

export default function FeaturesDialog({open,setOpen,data}) {
    const handleClose = () => {
        setOpen(false);
      };
  return (
        <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        sx={{'.MuiPaper-root':{bgcolor:'transparent',boxShadow:'none'},display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center'}}
      >
        <DialogContent>
        <div className="card-containerrr">
                <img src={data.profile_photo_url} alt="loading" style={{ width: '120px', height: '120px' }} referrerPolicy="no-referrer" /><br />
                <Container maxWidth='sm'>
                  <p style={{color:'#7B3AE2',fontSize:'15px'}}>{data.title}</p>
                  <p>{data.text}</p>
                  </Container>
              </div>
        </DialogContent>
      </Dialog>
  )
}
