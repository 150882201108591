import {
  Alert,
  Backdrop,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import pic from "../Media/Container.png";
import im from "../Media/SH.png";
import TextFieldComp from "../Component/TextFieldComp";
import PhoneInput from "react-phone-input-2";
import { CountryDropdown } from "react-country-region-selector";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../Component/LoadingPage";
import Success from "../Component/Success";

export default function CV() {
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [genderList, setGenderList] = useState([
    { id: "male", title: "Male" },
    { id: "female", title: "Female" },
  ]);
  const [nationality, setNationality] = useState("");
  const [nationalityِِArray, setNationalityArray] = useState([]);
  const [address, setAddress] = useState("");
  const [linked, setLinked] = useState([]);
  const [attachement, setAttachement] = useState("");
  const [data, setData] = useState();
  const [alerting, setAlerting] = React.useState(false);
  const windowHeight = useRef(window.innerHeight);
  const matches = useMediaQuery("(min-width:800px)");
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showProgress , setShowProgress] = useState(false)

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAttachement(file);
      const formData = new FormData();
      formData.append("job_id", data.job_id);
      formData.append("cv_file[]", file);
      setShowProgress(true)
      setOpenSuccess(false)
      axios.post(`${process.env.REACT_APP_API_URL}send-cv`, formData, {
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      })
      .then(res =>{
        if(res.data.status === true){
          setOpenSuccess(true)
        }
      })
      .catch(error => {
        setShowProgress(false);
       setUploadProgress(0); 

    if (error.response) {
      if (error.response.status === 500) {
        setShowProgress(false)
        alert('Failed to send the CV')
        setOpenSuccess(false)
        } 
    } 
    else {
      console.error("Error setting up the request:", error.message);
      alert("An error occurred while preparing the request. Please try again.");
    }
      });
    }
  };
 
  const handleButtonClick = () => {
    document.getElementById("fileInput").click();
  };
  const currentPageUrl = window.location.href;
  let desiredUrl;
  const hashIndex = currentPageUrl.indexOf("#");

  if (hashIndex !== -1) {
    const hashFragment = currentPageUrl.substring(hashIndex + 1);
    const queryIndex = hashFragment.indexOf("?");

    if (queryIndex !== -1) {
      const queryString = hashFragment.substring(queryIndex + 1);
      const queryParameters = new URLSearchParams(queryString);

      let job = queryParameters.get("job_id");
      let token = queryParameters.get("token");
      if (job && token) {
        job = decodeURIComponent(job);
        token = decodeURIComponent(token);
      } else {
        const decodedString = decodeURIComponent(queryString);
        const params = new URLSearchParams(decodedString);

        job = params.get("job_id");
        token = params.get("token");
      }
      desiredUrl = `?link=https://swifthire.ai/%23/cv?job_id=${job}%26token=${token}&type=link`;
    }
  }
  var head = "zdmZXd%cK$!Qq8FQ2psdXpO5@EP$4aMJujas";
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${process.env.REACT_APP_API_URL}job-info${desiredUrl}`, {
        headers: {
          "X-Api-Password": head,
        },
      })
      .then((res) => {
        setData(res.data.data);
        setOpen(false);
        setNationalityArray(res.data.data.countries)
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          navigate("/notfound");
        } else {
          console.error(error);
        }
      });
  }, []);

  const handleSubmit = () => {
    if (
      name &&
      lastname &&
      nationality &&
      gender &&
      phone &&
      email &&
      address &&
      linked  
    ) {
      const formData = new FormData();
      formData.append("first_name", name);
      formData.append("last_name", lastname);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("gender", gender);
      formData.append("nationality", nationality);
      formData.append("linkedin_url", linked);
      formData.append("job_id", data.job_id);
      formData.append("cv_details", data.job_title);

      axios
        .post(`${process.env.REACT_APP_API_URL}store-candidate`, formData)
        .then((res) => {
          if (res.data.status === true) {
            setOpenSuccess(true);
            setTimeout(() => {
              navigate("/");
            }, 2100);
          }
        });
    } 
    else{
      setAlerting(true);
    }
  };

  useEffect(()=>{
    if(uploadProgress === 100 && openSuccess){
      setOpenSuccess(true);
      setTimeout(() => {
        navigate("/");
      }, 2100);
    }
  },[uploadProgress, openSuccess])

  return (
    <div
      style={{
        backgroundColor: "#D9D9D9",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        padding: "30px",
        minHeight: windowHeight.current,
      }}
    >
      {open && <LoadingPage open={open} />}
      {!open && (
        <Box
          sx={{
            bgcolor: "white",
            borderRadius: "20px",
            width: "95%",
            minHeight: "500px",
            mt: "100px",
            position: "relative",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              width: "100%",
              height: "300px",
              borderRadius: "20px 20px 0 0",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              borderRadius: "20px 20px 0 0",
              borderBottom:'1px solid rgba(0, 0, 0, 0.5)'
            }}
          >
            {data && (
              <>
                <img
                  src={data.company_logo}
                  style={{ width: "150px", height: "150px" }}
                />
                <span
                  style={{
                    color: "black",
                    fontSize: "15px",
                    fontWeight: "bold",
                    marginTop: "10px",
                  }}
                >
                  {data.company_name}
                </span>
                <span
                  style={{
                    color: "black",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  {data.job_title}
                </span>
              </>
            )}
          </Box>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "40px",
            }}
          >
            <Button
              sx={{
                bgcolor: "#7C3BE1",
                color: "white",
                width: "50%",
                p: "15px",
                textAlign: "center",
                '&:hover':{ bgcolor: "#7C3BE1",
                color: "white",}
              }}
              onClick={handleButtonClick}
            >
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none",width:'100%' }}
                  onChange={handleFileChange}
                />
              Upload your CV
            </Button>
          </div>

        <> <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", width: "50%" }}
            >
              <div
                style={{ flex: 1, height: "1px", backgroundColor: "#7D7D7D" }}
              />
              <span
                style={{
                  color: "#7D7D7D",
                  fontSize: "15px",
                  padding: "0 10px",
                  textAlign: "center",
                }}
              >
                Or fill the following form
              </span>
              <div
                style={{ flex: 1, height: "1px", backgroundColor: "#7D7D7D" }}
              />
            </div>
          </div>
          <Grid container spacing={7} sx={{ p: "20px" }}>
            <Grid item xs={12} sm={matches ? 3 : 6}>
              <TextFieldComp
                label={"First Name"}
                onChange={setName}
                value={name}
                h={"40px"}
                width={"100%"}
              />
            </Grid>

            <Grid item xs={12} sm={matches ? 3 : 6}>
              <TextFieldComp
                label={"Last Name"}
                onChange={setLastName}
                value={lastname}
                h={"40px"}
                width={"100%"}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={matches ? 3 : 6}
              style={{ marginTop: "10px" }}
            >
              <label
                style={{
                  color: "black",
                  fontSize: "12px",
                }}
              >
                Phone Number
              </label>
              <PhoneInput
                country={"sa"}
                enableSearch={true}
                value={phone}
                onChange={setPhone}
                inputStyle={{
                  backgroundColor: "white",
                  color: "#7B3AE2",
                  fontSize: "13px",
                  border: "0.1px solid #7B3AE2",
                  borderRadius: "5px",
                  height: "40px",
                  direction: "ltr",
                  width: "100%",
                }}
                style={{
                  marginTop: "5px",
                  ".flag-dropdown ": { backgroundColor: "white" },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={matches ? 3 : 6}>
              <TextFieldComp
                label={"Email"}
                onChange={setEmail}
                value={email}
                h={"40px"}
                width={"100%"}
                type={"email"}
              />
            </Grid>
          </Grid>

          <Grid container spacing={7} sx={{ p: "20px" }}>
            <Grid item xs={12} sm={matches ? 3 : 6} sx={{ mt: "5px" }}>              
                <TextFieldComp
                label={"Nationality"}
                onChange={setNationality}
                value={nationality}
                h={"40px"}
                width={"100%"}
                data={nationalityِِArray}
                searchable={true}
              />
            </Grid>

            <Grid item xs={12} sm={matches ? 3 : 6}>
              <TextFieldComp
                label={"Gender"}
                onChange={setGender}
                value={gender}
                h={"40px"}
                width={"100%"}
                data={genderList}
              />
            </Grid>

            <Grid item xs={12} sm={matches ? 3 : 6}>
              <TextFieldComp
                label={"Address"}
                onChange={setAddress}
                value={address}
                h={"40px"}
                width={"100%"}
              />
            </Grid>

            <Grid item xs={12} sm={matches ? 3 : 6}>
              <TextFieldComp
                label={"LinkedIn Profile URL"}
                onChange={setLinked}
                value={linked}
                h={"40px"}
                width={"100%"}
              />
            </Grid>
          </Grid>

          {/* <Grid
            container
            spacing={7}
            sx={{
              p: "20px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "end",
            }}
          >
            <Grid item xs={12} sm={matches ? 3 : 6}>
              <Button
                sx={{
                  bgcolor: "#6DD6AA",
                  color: "white",
                  width: "100%",
                  padding: "10px",
                  "&:hover": { bgcolor: "#6DD6AA", color: "white" },
                  borderRadius: "5px",
                }}
                onClick={handleButtonClick}
              >
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <AttachFileIcon sx={{ rotate: "45deg" }} />
                Attach CV
              </Button>
            </Grid>
          </Grid> */}
          <Grid
            container
            sx={{
              p: "20px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {alerting && (
              <Alert
                severity="error"
                sx={{ width: matches ? "350px" : "310px", marginTop: "20px" }}
              >
                All fields must be filled.
              </Alert>
            )}
          </Grid>
          </>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              marginTop:'20px'
            }}
          >
            <Button
              sx={{
                bgcolor: "#7C3BE1",
                color: "white",
                width: "30%",
                p: "15px",
                "&:hover": { bgcolor: "#7C3BE1", color: "white" },
                textTransform: "none",
                fontWeight: "bold",
                mb: "20px",
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Box>
      )}
      {uploadProgress > 0 && (
        <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={showProgress}
      
      >
        <Stack
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1>{uploadProgress}%</h1>
        </Stack>
      </Backdrop>
    )}
      <Success
        open={openSuccess}
        setOpen={setOpenSuccess}
        msg={"Thank you for applying, we will contact you shortly"}
      />
    </div>
  );
}
