import React, { useState } from 'react';
import { TextField, MenuItem } from '@mui/material';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function TextFieldComp({
  label,
  value,
  onChange,
  width,
  h,
  type,
  data,
  searchable
}) {
  const [emailError, setEmailError] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
      const newValue = e.target.value;
      setEmailError(type === 'email' && !validateEmail(newValue));
      onChange(newValue);
  };

  const customTheme = (outerTheme) =>
    createTheme({
      palette: {
        mode: outerTheme.palette.mode,
      },
      components: {
        MuiInputLabel: {
          styleOverrides: {
            root: {
              color: "#7B3AE2",
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#7B3AE2",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#7B3AE2",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#7B3AE2",
              },
            },
          },
        },
      },
    });

  const outerTheme = useTheme();

  return (
    <div style={{ marginTop: '10px' }}>
      <label
        style={{
          color: 'black',
          fontSize: '12px',
        }}
      >
        {label}
      </label>
      <br />

      <ThemeProvider theme={customTheme(outerTheme)}>
        <TextField
          select={!!data} 
          value={value}
          onChange={handleChange}
          error={type === 'email' && emailError}
          helperText={
            type === 'email' && emailError
              ? 'Please enter a valid email address'
              : ''
          }
          sx={{
            width: width,
            letterSpacing: '0px',
            height: h,
            mt: '5px',
            '.MuiInputBase-root ': { height: h, borderRadius: '5px' },
          }}
        >
           {searchable &&  
            <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              onClick={(e) => e.stopPropagation()}
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>}
          {data &&
            data.map((item, index) => (
              <MenuItem key={index} value={item.id || item}>
                {item.title || item}
              </MenuItem>
            ))}
        </TextField>
      </ThemeProvider>
    </div>
  );
}
