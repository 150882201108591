import React, { useCallback, useEffect, useRef, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Backdrop, Button, Container, Stack, TextField } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import LoadingPage from "../Component/LoadingPage";
import { ReactMediaRecorder } from "react-media-recorder-2";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: matches ? 3 : "" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  tabLabel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    textTransform: "none",
  },
  minutesLabel: {
    color: "#4AA299",
  },
}));
export default function Questions() {
  const [value, setValue] = useState(0);
  const [answers, setAnswers] = useState(Array(6).fill(""));
  const windowHeight = useRef(window.innerHeight);
  const [isAnswering, setIsAnswering] = useState(false);
  const [questions, setQuestions] = useState([]);
  const matches = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const [progres , setProgres] = useState(0)
  const [showProgress , setShowProgress] = useState(false)
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const { state } = location;
  const { data, lang , picture,
    co_name,
    position} = state;
  var head = "zdmZXd%cK$!Qq8FQ2psdXpO5@EP$4aMJujas";
  localStorage.setItem("id", data);
  localStorage.setItem("lang", lang);
  const [mediaStream, setMediaStream] = useState(null);
  const [rec, setRec] = useState(false);
  const videoRef = useRef(null);
  let [time, setTime] = React.useState(0);
  var startButtonRef = useRef(null);
  const endButtonRef = useRef(null);
  const [id, setId] = React.useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [dur, setDur] = useState('')
  const [progressArray, setProgressArray] = useState(new Array(questions.length).fill(0));
  const [uploadQueue, setUploadQueue] = useState([]);
  const [last,setLast] = useState(false)
  
  useEffect(() => {
    window.onbeforeunload = (e) => {
      e.preventDefault();
      };
      window.onload = (e) => {
        e.preventDefault();
        navigate('/')
        window.location.reload(true);
        };
        window.addEventListener('popstate', (e) => {
          e.preventDefault();
         if(window.confirm('Sorry once you start the interview you have to complete it, are you sure you want to leave ?')){
          navigate('/')
         }
         else{
          navigate("/questions", {
            state: {
              data: localStorage.getItem("id"),
              lang: localStorage.getItem("lang"),
            },
          });
        }
        });
}, []);


useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("id", data);
    setProgressArray(new Array(questions.length).fill(0))
  }, [questions]);

  
  const handleZero = () => {
    starting();
    setRec(false);
    setMinutes(0);
    setSeconds(0);
  };
  var timer;
  var t;

  useEffect(() => {
    if (isAnswering) {
      t = setInterval(() => {
        if (minutes === dur-1 && seconds === 59) {
          clearInterval(t);
          if (endButtonRef.current) {
            endButtonRef.current.click();
          }
        } else {
          if (seconds === 59) {
            setMinutes((prevMinutes) => prevMinutes + 1);
            setSeconds(0);
          } else {
            setSeconds((prevSeconds) => prevSeconds + 1);
          }
        }
      }, 1000);
    }
    return () => {
      clearInterval(t);
    };
  }, [isAnswering, minutes, seconds]);

  React.useEffect(() => {
    if (isAnswering) {
      timer = setInterval(() => {
        setTime((prevProgress) => (prevProgress === 0 ? 0 : prevProgress - 1));
      }, 1000);
      setTimeout(() => {
        clearInterval(timer);
        handleZero();
      }, 10);
    }
    return () => {
      clearInterval(time);
    };
  }, [isAnswering]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = mediaStream;
    }
    if (startButtonRef.current) {
      startButtonRef.current.click();
    }
  }, [mediaStream]);

  const starting = () => {
    if (isAnswering) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          setMediaStream(stream);
        })
        .catch((error) => {
          console.error("Error accessing camera:", error);
        });
    }
  };

  let lan;
  useEffect(() => {
    if (lang === "Arabic") {
      lan = "ar";
    }
    if (lang === "English") {
      lan = "en";
    }
    if (lang === "en") {
      lan = "en";
    }
    axios
      .get(
        `${process.env.REACT_APP_API_URL}questions?candidate_id=${data}&lang=${lan}`,
        {
          headers: {
            "X-Api-Password": head,
          },
        }
      )
      .then((res) => {
        setQuestions(res.data.data);
        setOpen(false);
      });
  }, []);
  const isLastQuestion = value === questions.length - 1;

//   useEffect(() => {
//     window.onbeforeunload = (e) => {
//       e.preventDefault();
//       };
//       window.onload = (e) => {
//         e.preventDefault();
//         navigate('/')
//         window.location.reload(true);
//         };
//         window.addEventListener('popstate', (e) => {
//           e.preventDefault();
//          if(window.confirm('Sorry once you start the interview you have to complete it, are you sure you want to leave ?')){
//           navigate('/')
//          }
//          else{
//           navigate("/questions", {
//             state: {
//               data: localStorage.getItem("id"),
//               lang: localStorage.getItem("lang"),
//             },
//           });
//         }
//         });
// }, []);
 
  
  const start = () => {
    setIsAnswering(true);
  };

  const stopCamera = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  useEffect(()=>{
  if(value === questions.length)
  {
    setLast(true)
  }
},[value])



const handleStopRecording = async (videoBlob) => {
    stopCamera();
    setTime(10);
    setRec(true);
    setIsAnswering(false);
    if (isLastQuestion) {
      setShowProgress(true);
    }
  
    const file = await fetch(videoBlob)
      .then((r) => r.blob())
      .then((blobFile) => new File([blobFile], "record.mp4", { type: blobFile.type }));
  
    const formData = new FormData();
    formData.append("answer_video", file);
    formData.append("question_id", questions[value].id);
    formData.append("candidate_id", data);
    const head = "zdmZXd%cK$!Qq8FQ2psdXpO5@EP$4aMJujas";
  
    if (file.type === "video/mp4") {
      const newUpload = {
        formData: formData,
        progress: 0,
      };
  
      setUploadQueue((prevQueue) => [...prevQueue, newUpload]);
  
      const instance = axios.create();
      instance.defaults.headers.common["X-Api-Password"] = head;
  
      instance.post(`${process.env.REACT_APP_API_URL}answer`, formData, {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
  
          setProgressArray((prevArray) => {
            const newProgressArray = [...prevArray];
            newProgressArray[value] = progress;
  
            const filteredArray = newProgressArray.filter((val) => typeof val === 'number');
            const allComplete = filteredArray.every((val) => val === 100);
            setProgres(filteredArray.length > 0 ? Math.min(...filteredArray) : 0);
        
            if (last && allComplete ) {
              setShowProgress(false);
              const fData = new FormData();
              fData.append("candidate_id", data);
              setTimeout(() => {
                axios.post(`${process.env.REACT_APP_API_URL}finish-answering`, fData)
                .then((res) => {
                  if (res.data.status === true) {
                    setShowProgress(false);
                    navigate("/done",{state:{picture:picture,
                      co_name:co_name,
                      position:position}});
                  }
                })
                .catch((error) => {
                  if (error.response && error.response.status === 500) {
                    navigate("/done",{state:{picture:picture,
                      co_name:co_name,
                      position:position}});
                  } else {
                    console.log(error);
                  }
                });
                }, 2000);
            }
  
            return newProgressArray;
          });
  
          setUploadQueue((prevQueue) => {
            const updatedQueue = prevQueue.map((upload) =>
              upload === newUpload ? { ...upload, progress: progress } : upload
            );
            return updatedQueue;
          });
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          setUploadQueue((prevQueue) => prevQueue.filter((upload) => upload !== newUpload));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
    setValue(value + 1);
  };
  


  const classes = useStyles();

  const handleChange = (event, newValue) => {
    if (answers[value] !== "") {
      setValue(newValue);
    }
  };
  

  return (
    <div
      style={{ minHeight: windowHeight.current, backgroundColor: "#F1F3F6" }}
    >
      {open && <LoadingPage open={open} />}
      {!open && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "30px",
          }}
        >
          <Stack
            sx={{
              marginTop: "100px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: matches ? "start" : "center",
              alignItems: matches ? "start" : "center",
            }}
          >
            <Tabs
              orientation={matches ? "" : "vertical"}
              value={value}
              onChange={handleChange}
              textColor="inherit"
              sx={{
                width: "100%",
                "& .MuiTabs-indicator": {
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#4AA299",
                  height: "3px",
                },
              }}
            >
              {questions.map((q, i) => (
                <Tab
                  label={
                    <div className={classes.tabLabel}>
                      Question {i + 1}
                      <span className={classes.minutesLabel}>
                        {q.duration} minutes
                      </span>
                    </div>
                  }
                />
              ))}
            </Tabs>
            <Box
              sx={{
                width: matches ? "1000px" : "98%",
                borderRadius: "20px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                bgcolor: "white",
                marginTop: "20px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {questions.map((q, i) => (
                <TabPanel value={value} index={i}>
                  <Stack
                    gap={2}
                    sx={{
                      p: "20px",
                      position: "relative",
                      minHeight: "300px",
                      justifyContent: matches ? "center" : "center",
                      alignItems: matches ? "center" : "center",
                    }}
                  >
                    {isAnswering && (
                      <>
                        {!rec && (
                          <>
                            <ReactMediaRecorder
                              video
                              stopStreamsOnStop={true}
                              render={({
                                startRecording,
                                stopRecording,
                                mediaBlobUrl,
                              }) => (
                                <Stack
                                  gap={2}
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {!rec && (
                                    <>
                                      <video
                                        ref={videoRef}
                                        style={{
                                          width: matches ? "100%" : "200px",
                                        }}
                                        autoPlay
                                      />
                                      {matches && (
                                        <Typography
                                          sx={{
                                            position: "absolute",
                                            top: "430px",
                                            right: "80px",
                                            color: "white",
                                          }}
                                        >
                                          {minutes.toString().padStart(2, "0")}:
                                          {seconds.toString().padStart(2, "0")}
                                        </Typography>
                                      )}
                                    </>
                                  )}
                                  <Stack
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      justifyContent: "center",
                                      alignItems:'center'
                                    }}
                                  >
                                    <Button
                                      sx={{
                                        bgcolor: "transparent",
                                        cursor: "inherit",
                                        "&:hover": {
                                          color: "transparent",
                                          bgcolor: "transparent",
                                        },

                                      }}
                                      ref={startButtonRef}
                                      onClick={() => {
                                        startRecording();
                                        setId(q.id);
                                        setDur(q.duration);
                                      }}
                                    ></Button>
                                    {seconds > 2 && (
                                      <Button
                                        ref={endButtonRef}
                                        onClick={() => {
                                          stopRecording();
                                          stopCamera();
                                          handleStopRecording(mediaBlobUrl);
                                        }}
                                        sx={{
                                          height: "40px",
                                          color: "white",
                                          bgcolor: "#0D969B",
                                          "&:hover": {
                                            color: "white",
                                            bgcolor: "#0D969B",
                                          },
                                          padding: "5px 20px 5px 20px",
                                          mb: "20px",
                                          borderRadius: "20px",
                                          mt: "10px",
                                        }}
                                      >
                                        {isLastQuestion
                                          ? "Finish Interview"
                                          : "Next Question"}
                                      </Button>
                                    )}
                                  </Stack>
                                </Stack>
                              )}
                              onStop={handleStopRecording}
                            />
                          </>
                        )}
                      </>
                    )}
                    {!isAnswering && (
                      <>
                        <video
                          style={{
                            width: matches ? "100%" : "200px",
                            height: matches ? "350px" : "",
                          }}
                          src={`${process.env.REACT_APP_API_URL_IMAGE}${q.video}`}
                          autoPlay={true}
                          onEnded={() => {
                            handleZero();
                            start();
                          }}
                        />
                      </>
                    )}
                  </Stack>
                </TabPanel>
              ))}
            </Box>
          </Stack>
        </Box>
      )}

      <Stack
        direction={matches ? "row" : "column"}
        gap={matches ? "750px" : 1}
        sx={{ justifyContent: "center", alignItems: "center" }}
      >
      </Stack>


                              {showProgress && (
                          <Backdrop
                            sx={{
                              color: "#fff",
                              zIndex: (theme) => theme.zIndex.drawer + 1,
                            }}
                            open={showProgress}
                          
                          >
                            <Stack
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <h1>Please wait you're almost there...</h1>
                              <h1>{progres}%</h1>
                            </Stack>
                          </Backdrop>
                        )}
    </div>
  );
}

