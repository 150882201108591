import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  useMediaQuery,
  Container,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import pic from "../Media/Done.png";
import { useLocation, useNavigate } from "react-router-dom";
import Survey from "../Component/Survey";

export default function EndPage() {
  const location = useLocation();
  const { state } = location;
  const { picture, co_name,
    position} = state;
  const [open, setOpen] = useState(false);
  const windowHeight = useRef(window.innerHeight);
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:500px)");
  var id = localStorage.getItem("id");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const name = localStorage.getItem("name");


  return (
    <div
      style={{
        minHeight: windowHeight.current,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "start",
        backgroundColor: "#F1F3F6",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "80%",
          borderRadius: "20px",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          minHeight: "500px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "white",
          marginTop: "130px",
          mb: "50px",
        }}
      >
        {matches && (
          <img
            src={pic}
            style={{ position: "absolute", top: "0px", left: "0px" }}
          />
        )}
        <Stack
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
          gap={2}
        >
          <img
            src={picture}
            style={{
              height: matches ? "250px" : "200px",
              width: matches ? "250px" : "200px",
              objectFit: "contain",
              marginTop: "20px",
            }}
          />
          <Typography
            sx={{ color: "#777777", fontSize: "15px", mt: "20px", mb: "20px" }}
          >
            <Container sx={{ width: matches ? "80%" : "100%",ml:matches?'15%':'' }}>
              Thank you, <strong>{name}</strong>, for completing your interview
              for the <strong>{position}</strong> position at <strong>{co_name}</strong>. <br /><br />
              We've successfully received your responses.
              Our team will carefully review your interview as part of our
              selection process.
              <br /><br /> We appreciate your interest in <strong>{co_name}</strong> and the time
              you've invested in this process. <br /><br />
              Best regards,<br />
              <strong>{co_name}</strong> Hiring Team.
            </Container>
          </Typography>
          <Button
            onClick={() => {
              setOpen(true);
            }}
            sx={{
              color: "white",
              bgcolor: "#0D969B",
              "&:hover": { color: "white", bgcolor: "#0D969B" },
              padding: "5px 20px 5px 20px",
              mb: "20px",
              borderRadius: "20px",
              mt: "10px",
            }}
          >
            Provide Feedback
          </Button>
        </Stack>
      </Box>
      <Survey open={open} id={id} />
    </div>
  );
}
