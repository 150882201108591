import React, { useEffect, useState } from "react";
import bg from "../Media/reviews.png";
import {
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";
import "../App.css";
import "swiper/css/pagination";
import q1 from "../Media/qoute.png";
import q2 from "../Media/qoute2.png";
import ReviewsDialog from "./ReviewsDialog";

export default function Reviews({ review }) {
  const matches = useMediaQuery("(min-width:800px)");
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");

  const handleReadMore = (selectedData) => {
    setOpen(true);
    setData(selectedData);
  };

  if(review.length > 0){
    localStorage.setItem('show',true)
  }
  if(review.length === 0){
    localStorage.setItem('show',false)
  }

  return (
    <>
   {review.length > 0 && <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        marginTop: "60px",
        position: "relative",
        minHeight: "600px",
      }}
      id="Reviews"
    >
      <Container
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "#7B3AE2",
            fontSize: "30px",
            fontWeight: "bold",
            mt: "30px",
          }}
        >
          What Has Been Said About Swift Hire
        </Typography>
      </Container>
      <Container maxWidth="md">
        <Swiper
          modules={[Autoplay, Pagination]}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          initialSlide={1}
          slidesPerView={matches ? 3 : 1}
          spaceBetween={30}
          pagination={{ clickable: true }}
          style={{
            paddingBottom: "50px",
            paddingTop: "55px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
          className="custom-swiper"
        >
          {review.map((r, i) => (
            <SwiperSlide
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div class="Reviews-card">
                <img
                  src={q1}
                  style={{
                    position: "absolute",
                    top: "5px",
                    left: "1px",
                    width: "70px",
                  }}
                />
                <img
                  src={q2}
                  style={{
                    position: "absolute",
                    bottom: "5px",
                    right: "1px",
                    width: "70px",
                  }}
                />
                <Stack
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: "20px",
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_API_URL_IMAGE}${r.logo}`}
                    alt="...loading"
                    style={{
                      borderRadius: "100%",
                      width: "100px",
                      height: "100px",
                    }}
                  />
                  <br />
                  <Container>
                    <p>
                      {`${r.feedback.substring(0, 50)} `}
                      {r.feedback.length > 40 && (
                        <Button
                          sx={{
                            color: "#7B3AE2",
                            "&:hover": { color: "#7B3AE2" },
                            fontSize: "12px",
                          }}
                          onClick={() =>
                            handleReadMore({
                              profile_photo_url: `${process.env.REACT_APP_API_URL_IMAGE}${r.logo}`,
                              name: r.name,
                              text: r.feedback,
                              cname: r.company_name,
                            })
                          }
                        >
                          Read More
                        </Button>
                      )}
                    </p>
                  </Container>
                  <p
                    style={{
                      color: "#7B3AE2",
                      fontWeight: "normal",
                      position: "absolute",
                      bottom: "20px",
                    }}
                  >
                    {r.name} <br />
                    <br />
                    {r.company_name}
                  </p>
                </Stack>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
      <ReviewsDialog open={open} setOpen={setOpen} data={data} />
    </div>}
    </>
  );
}
